.perspective-container {
    perspective: 1000px; /* Define la perspectiva para las imágenes */
  }
  
  .image-hover {
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out, filter 0.5s ease-in-out, box-shadow 0.5s ease-in-out; /* Animación suave */
    transform: rotateY(-30deg) scale(0.9); /* Rotación inicial hacia la izquierda */
    filter: brightness(1); /* Brillo inicial */
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.2); /* Resplandor inicial tenue */
  }
  
  .image-hover.middle {
    transform: rotateY(30deg) scale(0.9); /* Rotación inicial hacia la derecha */
  }
  
  .image-hover:hover {
    transform: rotateY(0deg) scale(1.1); /* Endereza y aumenta el tamaño en hover */
    filter: brightness(1.3); /* Incrementa el brillo en hover */
    box-shadow: 0 0 40px rgba(255, 255, 255, 0.4); /* Resplandor más intenso */
  }
  
  .overlay-dark {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo negro con opacidad al 50% */
    z-index: 1; /* Coloca el overlay encima del video */
  }
  
  .title-gradient {
    background: linear-gradient(to right, #C7AA68, #A68A50);
    -webkit-background-clip: text;
    color: transparent;
  }
  