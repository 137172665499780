@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

header {
    height: 70px; /* Altura fija */
    width: 100%;
    z-index: 10;
    position: sticky;
    top: 0; /* Se queda en la parte superior mientras haces scroll */
    background-color: #283941 !important; /* Cambia al color deseado */
    align-content: center;
  }
  
  .navbar-brand,
  .nav-link {
    color: #C7AA68 !important;
    font-weight: bold;
  }

